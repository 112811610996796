@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    padding: 0;
    margin: 0;
    color: theme('colors.black');
}

h1{
    font: bold;
    font-size: 3rem;
    color: theme('colors.primary');
}

h2{
    font: bold;
    font-size: 2rem;
    color: theme('colors.primary');
}

p{
    font-size: 1.5rem;
}

body {
    background-color: theme('colors.white');
}

.link{
    color: theme('colors.primary');
    font-size: 1.5rem;
}

.link:hover{
    color: theme('colors.secondary');
}

