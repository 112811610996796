.desktop-image{
  /* transform: perspective(1000px) rotateX(0deg) rotateY(0deg); */
  transform-style: preserve-3d; 
  transform: perspective(1000px) rotateX(0deg) rotateY(-30deg) rotateZ(0deg);
}

.desktop-shadow{
  transform-style: preserve-3d; 
  transform: translateY(13rem) perspective(1000px) rotateX(80deg) rotateY(-2deg) rotateZ(30deg);    
  filter: blur(0.8rem);
}